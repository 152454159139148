
import React, { useEffect, useState , useRef} from 'react'

import { FaSpinner } from 'react-icons/fa'
import { MdOutlineHelp } from 'react-icons/md'
import { insertImage, insertionTableauImage } from './GexWord';
import CONFIG from './config.js'
import Fetching from './Fetching';
import { icons } from 'react-icons';

export const ExpertiseImageGallery = (props) => {


    const [tblData, settblData] = useState([])
    const [tblSelect, settblSelect] = useState([])
    const [NbColonne, setNbColonne] = useState(1)
    const [AvecAnnotation, setAvecAnnotation] = useState(false)
    const [AvecNumerotation, setAvecNumerotation] = useState(false)
    const [MultiSelection, setMultiSelection] = useState(false)
    const [enFetching, setenFetching] = useState(false)
    const [enChargementPhoto, setEnChargementPhoto] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null);
    
    const refFichier = useRef();

  
    
    function getFile(file,i) {
        var reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = function () {
            //setApercuFichierPDF(reader.result);
            settblData((oldData,props) => [...oldData,{fiId:(999999+i),fiPhotoNumero:0,fiNotes:'', fiNom : reader.name, fiContenu:reader.result}]);
        };
    }

    useEffect(() => {
        if(!selectedFile) {
            setEnChargementPhoto(false)
            return;
        }
        
        let i=0;
        for(i=0;i<selectedFile.length;i++) {
           getFile(selectedFile[i],i)
        }
        refFichier.current.value=""
        setEnChargementPhoto(false)
    }, [selectedFile]);

    function getData() {

        fetch(CONFIG.URL + '/expertise/getImages/' + props.exid, { headers: { 'Token': props.token } })
            .then(res => res.json())
            .then(
                (result) => {
                    settblData(JSON.parse(result));
                  //  settblSelect(JSON.parse(result))
                },
                (error) => {
                    console.log(error);
                    settblData([]);
                }
            )

    }

    useEffect(() => {
        getData();

    }, [])

    function trclick(e, element) {
        if (e.ctrlKey || MultiSelection) {
            if (!tblSelect.find(ele => ele == element)) {
                settblSelect([...tblSelect, element]);
            }
        } else {
            settblSelect([element])
        }


        if (e.detail >= 2) {
            getImage(element);

        }
    }

    function getImage(element) {
        setenFetching(true);
        if(element.fiContenu) {
            insertImage(element.fiContenu, element, AvecNumerotation, AvecAnnotation);
            setenFetching(false);
            return;
        }

        fetch(CONFIG.URL + '/expertise/getFichier/' + element.fiId, { headers: { 'Token': props.token } })
            .then(res => res.blob())
            .then(rawData => {
                const reader = new FileReader();
                reader.onload = () => {
                    insertImage(reader.result, element, AvecNumerotation, AvecAnnotation);
                    setenFetching(false);
                };
                reader.readAsDataURL(rawData);
            },
                (error) => {
                    setenFetching(false);
                    //settblData([]);
                }
            );
    }

    async function lecturePiece(file) {
        return new Promise((resolve, reject) => {
            let fileReader = new FileReader();
            fileReader.onload = function () {
                return resolve(fileReader.result);
            }
            fileReader.readAsDataURL(file);
        })
    }

    async function getTableauImage() {
        setenFetching(true)
        let i = 0;
        let tblLocal = tblSelect;

        for (i = 0; i < tblLocal.length; i++) {
            if(!tblLocal[i].fiContenu) {
                const response = await fetch(CONFIG.URL + '/expertise/getFichier/' + tblSelect[i].fiId, { headers: { 'Token': props.token } })
                const rawData = await response.blob();
    
                tblLocal[i].fiContenu = await lecturePiece(rawData);
            }
         
        }
        insertionTableauImage(tblLocal, NbColonne, AvecNumerotation, AvecAnnotation);
        settblSelect(tblLocal);
        setenFetching(false);
    }





    return <div className="col-lg-12">
        {enFetching ? <div>
            <Fetching></Fetching>
        </div> : null}
        <div className=' card shadow p-2 '>

            <div className='d-flex justify-content-between mb-2'>
                <h4 className="mx-2">Galerie de photos</h4>
                <div>
                    <button className='btn btn-primary mx-1' onClick={() =>
                        Office.context.ui.displayDialogAsync(window.location.origin + '/popup.html?URL=' + CONFIG.URL + '&exId=' + props.exid + "&action=GaleriePhoto&token=" + props.token, function (asyncResult) {
                            console.log(asyncResult);
                            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                                showNotification((asyncResult.error.code = ": " + asyncResult.error.message));
                            } else {
                                let dialog = asyncResult.value;
                                console.log(dialog);
                                dialog.addEventHandler(Office.EventType.DialogMessageReceived, function (arg) {
                                    console.log("Return", arg.message);


                                });
                            }
                        })}>Modifier les photos</button>
                    
                        <input style={{display : 'none'}} className='btn'  ref={refFichier} id="fileItem" type="file" onChange={(e) => setSelectedFile(e.target.files)} multiple/>
                        
                        <button className='btn btn-primary ' onClick={() => (setEnChargementPhoto(true),refFichier.current.click())}>Ajouter des fichiers locaux</button>
                    
                </div>
            </div>
            <div className='row'>
                <div className='col-sm-5 col-xs-12'>
                    <button style={{ display: tblSelect.length == 1 ? '' : 'none' }} className='btn btn-primary ' onClick={() => getImage(tblSelect[0])}>Insérer</button>


                    <button style={{ display: tblSelect.length > 1 ? '' : 'none' }} className='btn btn-primary ' onClick={getTableauImage}>Insérer les photos</button>
                    <button style={{ display: tblSelect.length > 1 ? '' : 'none' }} className='mx-1 btn btn-secondary ' onClick={() => settblSelect([])}>RAZ</button>
                </div>
                <div className='col-sm-5 col-xs-12' style={{ display: tblSelect.length > 1 ? '' : 'none' }}>

                    <label>Nombre de colonnes</label>
                    <input className='mx-1' type="number" min={1} max={4} onChange={(e) => setNbColonne(parseInt(e.target.value))} value={NbColonne}></input>
                </div>
            </div>
            <div className="row my-2 p-2">
                <div className='col-md-4 col-sm-6 col-xs-12'>
                    <div className="form-check form-switch">
                        <input type="checkbox" className="form-check-input" onChange={(e) => setAvecNumerotation(e.target.checked)}
                            checked={AvecNumerotation ? "checked" : ""}></input>   <label className="form-check-label">Avec la numérotation</label>
                    </div>
                </div>
                <div className='col-md-4 col-sm-6 col-xs-12'>
                    <div className="form-check form-switch ">
                        <input type="checkbox" className="form-check-input" onChange={(e) => setAvecAnnotation(e.target.checked)}
                            checked={AvecAnnotation ? "checked" : ""}></input>   <label className="form-check-label">Avec les annotations</label>
                    </div>


                </div>
                <div className='col-md-4 col-sm-6 col-xs-12'>
                    <div className="form-check form-switch ">
                        <input type="checkbox" className="form-check-input" onChange={(e) => setMultiSelection(e.target.checked)}
                            checked={MultiSelection ? "checked" : ""}></input>   <label className="form-check-label">MultiSelection</label>
                    </div>


                </div>
                <div className='col-md-4 col-sm-6 col-xs-12'>
                    <div><label>Zoom</label><input className='ml-3' type="range" min="100" defaultValue="200" max="600" step="10" onChange={(e) => {
                        var els = document.getElementsByClassName("image-gallery-li");
                        Array.prototype.forEach.call(els, function (el) {
                            el.style.flexBasis = e.target.value + 'px';
                        });

                    }} /></div>
                </div>

            </div>
            <ul className="image-gallery">
                {tblData.map((element, i) => {
                    return (<li className="image-gallery-li" id={i} style={{ flexBasis: '250px', backgroundColor: tblSelect.find(ele => ele == element) ? "#6c757d" : "" }} onMouseDown={(e) => trclick(e, element)} key={element.fiId}>
                        <img title={element.fiNotes} id={'FI' + element.fiId} src={element.fiContenu ? element.fiContenu : (CONFIG.URL + '/expertise/getThumb/' + element.fiId + "?auth=" + props.token )} alt="" />
                        <div className="overlay"><span style={{ color: tblSelect.find(ele => ele == element) ? "#FFF" : "" }} className='image-gallery-span'>{element.fiNom}{element.fiNotes != '' ? <span title={element.fiNotes}><MdOutlineHelp /></span> : ''}</span></div>
                    </li>
                    );


                })}
            </ul>
        </div>
    </div>

}